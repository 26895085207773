import React, { createContext, useState } from "react";
import RSA from "node-rsa";

const validateKey = (key) => {
  try {
    new RSA(key)
    return typeof key === "string"
  } catch (e) {
    return false;
  }
};

export const DecryptContext = createContext();
export function DecryptProvider({ children }) {
  const localKey = localStorage.getItem("user_dkey");
  const [key, setKey] = useState(localKey);
  let c, decrypt, isValid;
  try {
    c = new RSA(key);
    decrypt = (s = "") => {
      try {
        return c.decrypt(s, "utf8");
      } catch (e) {
        console.warn(e);
        return '';
      }
    }
    isValid = true;
  } catch (e) {
    console.warn(e);
    decrypt = (s) => ''
    isValid = false;
  }
  return (
    <DecryptContext.Provider
      value={{
        decrypt,
        key,
        isSet: !!key,
        isValid,
        setKey: (key) => {
          setKey(key);
          if (validateKey(key) || key === "") {
            localStorage.setItem("user_dkey", key);
          }
        },
      }}
    >
      {children}
    </DecryptContext.Provider>
  );
}
