import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import {
  intoObject,
  intoObjectKey,
  toState,
  branch,
  type,
  expand,
  pass
} from "redux-composable-reducers";

export const UserDetailsContext = createContext();
export function UserDetailsProvider({ children }) {
  // const [credential]  = useContext(UserContext)
  const credential = localStorage.getItem("user_credential");
  const [{ userDetailsMap, requestedUsers } = {}, dispatch] = useReducer(
    // Pass multiple actions of the same type at once to the reducer through the payload.
    expand()(
      branch({
        addUserDetails: intoObjectKey({
          userDetailsMap: intoObject(
            (
              s,
              {
                payload: {
                  user: { userIdentifier },
                },
              }
            ) => userIdentifier
          )(toState((s, { payload: { user } }) => user)(pass)),
        }),
        requestUserDetails: intoObjectKey({
          requestedUsers: intoObjectKey((s, { payload: userId }) => userId)(
            (user = {}, { payload: userId }) => ({
              ...user,
              userId,
              requested: true,
            })
          ),
        }),
        setUserPending: intoObjectKey({
          requestedUsers: intoObjectKey((s, { payload: userId }) => userId)(
            (user = {}, { payload: userId }) => ({
              ...user,
              userId,
              pending: true,
            })
          ),
        }),
        setUserRetrieved: intoObjectKey({
          requestedUsers: intoObjectKey((s, { payload: userId }) => userId)(
            (user = {}, { payload: userId }) => ({
              ...user,
              userId,
              pending: false,
              retrieved: true,
              retrievalFailure: false,
            })
          ),
        }),
        setUserRetrievedFailure: intoObjectKey({
          requestedUsers: intoObjectKey((s, { payload: userId }) => userId)(
            (user = {}, { payload: userId }) => ({
              ...user,
              userId,
              pending: false,
              retrieved: false,
              retrievalFailure: true,
            })
          ),
        }),
      })(type())
    ),
    {}
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      const userIds = Object.values(requestedUsers || {})
        .filter(
          ({ requested, retrieved, pending, retrievalFailure }) =>
            requested && !retrieved && !pending && !retrievalFailure
        )
        .map(({ userId }) => userId);
      dispatch({ payload: userIds.map(setUserPending) });
      const batchSize = 20;
      const userIdBatches = userIds.reduce((aa, userId, i) => {
        const j = Math.floor(i / batchSize);
        aa[j] = (aa[j] || []).concat(userId);
        return aa;
      }, []);
      userIdBatches.map((userIds) =>
        axios
          .request({
            method: "get",
            url: process.env.REACT_APP_SONDEPLATFORM_USER_DETAILS,
            headers: {
              Authorization: `${credential}`,
              "Content-Type": "application/json",
            },
            params: {
              userIdentifiers: userIds.join(","),
            },
          })
          .then(async ({ data }) => {
            return dispatch({
              payload: await Promise.all(
                userIds
                  .map(setUserRetrieved)
                  .concat((data.users || []).map(addUserDetails))
              ),
            });
          })
          .catch((e) => {
            dispatch({ payload: userIds.map(setUserRetrievedFailure) });
            console.error(e);
          })
      );
    }, 50);
    return () => {
      clearTimeout(handler);
    };
  }, [requestedUsers]);

  const addUserDetails = async (user) => {
    return {
      payload: { user },
      type: "addUserDetails",
    };
  };
  const requestUserDetails = (userId) => {
    return { payload: userId, type: "requestUserDetails" };
  };
  const setUserPending = (userId) => {
    return { payload: userId, type: "setUserPending" };
  };
  const setUserRetrieved = (userId) => {
    return { payload: userId, type: "setUserRetrieved" };
  };
  const setUserRetrievedFailure = (userId) => {
    return { payload: userId, type: "setUserRetrievedFailure" };
  };

  return (
    <UserDetailsContext.Provider
      value={{
        userDetailsMap,
        requestedUsers,
        requestUserDetails: (userId) => dispatch(requestUserDetails(userId)),
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
}
