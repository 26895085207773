import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import {
  // BrowserRouter,
  HashRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  LineChart,
  Line,
  ReferenceArea,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
import { DateTime } from "luxon";
import cubejs from "@cubejs-client/core";
import { CubeProvider, useCubeQuery } from "@cubejs-client/react";
import { useRef } from "react";
import { DecryptProvider, DecryptContext } from "./providers/DecryptProvider";
import {
  UserDetailsProvider,
  UserDetailsContext,
} from "./providers/UserDetailsProvider";
import { UserProvider, UserContext } from "./providers/UserProvider";

import "./App.css";
import "./three-dots.css";

function ScreenLoader() {
  return (
    <div className="fill-container fill-container__screen">
      <div className="dot-spin"></div>
    </div>
  );
}
function FigureLoader() {
  return (
    <div className="fill-container">
      <div className="dot-falling"></div>
    </div>
  );
}

function ClinicianLogin({ onLogin }) {
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  return (
    <form
      id="login"
      onSubmit={async (e) => {
        e.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        if (email === "" || email.charAt(0) === " ") {
          setError("Please provide a valid username or email.");
        } else if (password === "" || email.charAt(0) === " ") {
          setError("Please provide a valid password.");
        } else {
          setError(false);
          setProcessing(true);
          setPasswordVisible(false);
          try {
            await onLogin(emailRef.current.value, passwordRef.current.value);
          } catch (e) {
            setError("The username or password you entered is invalid.");
          }
          setProcessing(false);
        }
      }}
    >
      {processing ? <ScreenLoader /> : null}
      <img
        className="sonde-logo"
        src="https://images.squarespace-cdn.com/content/v1/5daafd349a9f9f7b4aa8680f/1576269885946-DVPPHN8GUENNAP3DYV1H/Sonde_Logo+horizontal+PMS3025.png?format=1500w"
      />
      <h2>Sonde Mental Fitness</h2>
      <h3>Clinician Portal</h3>
      <div className="login__field">
        <label htmlFor="email">Username or Email</label>
        <span className="login__icon material-icons">alternate_email</span>
        <input id="email" type="text" ref={emailRef} />
      </div>
      <div className="login__field">
        <label htmlFor="password">Password</label>
        <span className="login__icon material-icons">lock</span>
        <input
          id="password"
          type={passwordVisible ? "text" : "password"}
          ref={passwordRef}
        />
        {passwordVisible ? (
          <span
            className="login__icon login__icon-right login__visibility material-icons"
            onClick={() => {
              setPasswordVisible(false);
            }}
          >
            visibility_off
          </span>
        ) : (
          <span
            className="login__icon login__icon-right login__visibility material-icons"
            onClick={() => {
              setPasswordVisible(true);
            }}
          >
            visibility
          </span>
        )}
      </div>
      <div className="login__field">
        <button
          className="login__submit"
          type="submit"
          form="login"
          disabled={processing}
        >
          Sign In
        </button>
        {error ? <div className="login__error">{error}</div> : null}
      </div>
    </form>
  );
}

function UserDisplaySettings() {
  const {
    settings: { useEmail, useName },
    updateSettings,
  } = useContext(UserContext);
  const { isSet, isValid, key, setKey } = useContext(DecryptContext);
  return (
    <div className="user-display-settings">
      <div className="user-display-settings__container">
        <div className="user-display-settings__section-title">
          User Display Settings
        </div>
        <div className="user-display-settings__sub-section-title">
          User Information
        </div>
        {/* <div className="user-display-settings__option" onClick={(e) => updateSettings({ useEmail: !useEmail })}>
          <input
            type="checkbox"
            checked={useEmail}
          />
          <label htmlFor="useEmail">Display User Email</label>
        </div> */}
        <div
          className="user-display-settings__option"
          onClick={(e) => updateSettings({ useName: !useName })}
        >
          <input
            type="checkbox"
            checked={useName}
            onChange={(e) => updateSettings({ useName: e.target.checked })}
          />
          <label htmlFor="useName">Display Full Name</label>
        </div>
        <div className="user-display-settings__sub-section-title">
          Decryption Key (Paste Key Below)
        </div>
        {isSet && isValid ? (
          <div className="user-display-settings__field">
            <div className="user-display-settings__option">Key Saved</div>
            <div className="user-display-settings__option">
              <button onClick={() => setKey(null)}>Clear Key</button>
            </div>
          </div>
        ) : (
          <div>
            <textarea
              className={
                "key " +
                (isSet && isValid ? "key--valid" : !isSet ? "" : "key--invalid")
              }
              rows="27"
              cols="64"
              onChange={(e) => setKey(e.target.value)}
              value={key || ""}
            />
          </div>
        )}
        {isSet && !isValid ? <div>Invalid Key!</div> : null}
      </div>
    </div>
  );
}

function PatientDisplayName({ patientId }) {
  const {
    userDetailsMap: {
      [patientId]: {
        name: { firstName = "", lastName = "" } = {},
        email: { value: email = "" } = {},
      } = {},
    } = {},
    requestUserDetails,
  } = useContext(UserDetailsContext);
  useEffect(() => {
    requestUserDetails(patientId);
  }, [patientId]);
  const { settings: { useEmail, useName } = {} } = useContext(UserContext);
  const displayName =
    useName && firstName
      ? `${lastName}, ${firstName}`
      : useEmail && email
      ? email
      : patientId;
  return <div className="patient-display-name">{displayName}</div>;
}

function PatientGroupHome({ groupName }) {
  const { resultSet, isLoading, error, progress } = useCubeQuery({
    dimensions: [
      "PatientCheckIn.platform_user_id",
      "PatientCheckIn.platform_user_id",
    ],
  });
  const { userDetailsMap = {}, requestedUsers = {} } =
    useContext(UserDetailsContext);
  const usersPending = Object.values(requestedUsers || []).filter(
    ({ pending }) => pending
  );
  let patients = [];
  if (resultSet) {
    patients = resultSet.tablePivot();
  }
  const isReady = !(isLoading || usersPending.length > 0);
  return (
    <div className="patient-group">
      <h1 className="patient-group__title">{groupName} Group</h1>
      {isReady && patients.length === 0 ? (
        <div className="patient-group__not-found">No users found.</div>
      ) : (
        <div className="patients">
          <div className="patients__header">User List</div>
          <div className="patients__header-caption">Last Name, First Name</div>
          {!isReady ? <ScreenLoader /> : null}
          {isReady
            ? patients
                .sort((a, b) => {
                  const ua =
                    userDetailsMap[a["PatientCheckIn.platform_user_id"]];
                  const ub =
                    userDetailsMap[b["PatientCheckIn.platform_user_id"]];
                  if (ua && ua.name && ua.name.lastName) {
                    if (ub && ub.name && ub.name.lastName) {
                      return (ua.name.lastName || "").toUpperCase() >
                        (ub.name.lastName || "").toUpperCase()
                        ? 1
                        : -1;
                    } else {
                      return 1;
                    }
                  } else {
                    return -1;
                  }
                })
                .map(
                  (
                    {
                      ["PatientCheckIn.platform_user_id"]: platform_user_id,
                      accepted,
                    },
                    i
                  ) => {
                    // TODO: Do more with patient invitation response.
                    return (
                      <div
                        key={i}
                        className={`patient-link patient-link--invitation-${
                          accepted === undefined
                            ? "no-response"
                            : accepted
                            ? "accepted"
                            : "denied"
                        }`}
                      >
                        <Link to={`/user/${platform_user_id}`}>
                          <PatientDisplayName patientId={platform_user_id} />
                        </Link>
                      </div>
                    );
                  }
                )
            : null}
          {/**
           * TODO? This would be an appropriate place to create invitations.
           */}
        </div>
      )}
    </div>
  );
}
function KPICard({ label, children }) {
  return (
    <div className="card kpi-card">
      <div className="kpi-card__label">{label}</div>
      <div className="kpi-card__value">{children}</div>
    </div>
  );
}

function ChartCard({ title, children }) {
  return (
    <div className="card chart-card">
      <div className="chart-card__title">{title}</div>
      <div className="chart-card__value">{children}</div>
    </div>
  );
}

function CubeKPICard({
  query,
  getMeasure = (rs) => {
    return Object.values(rs.rawData()[0])[0];
  },
  ...props
}) {
  const { resultSet, isLoading, error, progress } = useCubeQuery(query);
  return (
    <KPICard {...props}>
      {isLoading ? (
        <FigureLoader />
      ) : error ? (
        ""
      ) : resultSet ? (
        getMeasure(resultSet)
      ) : null}
    </KPICard>
  );
}

function PatientSummary() {
  const { patientId } = useParams();
  const { userDetailsMap = {}, requestedUsers = {} } =
    useContext(UserDetailsContext);
  const timeDomain = [
    DateTime.now().endOf("day").minus({ weeks: 2 }),
    DateTime.now().endOf("day"),
  ];
  const { resultSet, isLoading, error, progress } = useCubeQuery({
    dimensions: [
      "PatientCheckIn.check_in_score",
      "PatientCheckIn.check_in_date_local",
    ],
    filters: [
      {
        member: "PatientCheckIn.platform_user_id",
        operator: "equals",
        values: [patientId],
      },
    ],
    timeDimensions: [
      {
        dimension: "PatientCheckIn.check_in_date_local",
        dateRange: timeDomain.map((t) => t.toSQLDate()),
        granularity: "hour",
      },
    ],
  });
  const usersPending = Object.values(requestedUsers || []).filter(
    ({ pending }) => pending
  );
  const isReady = !(isLoading || usersPending.length > 0);
  return (
    <div className="patient-summary">
      {!isReady ? <ScreenLoader /> : null}
      <h1 className="patient-summary__name">
        {isReady ? <PatientDisplayName patientId={patientId} /> : null}
      </h1>
      <div className="patient-summary__id">ID {patientId}</div>
      <div className="patient-summary__cards">
        <CubeKPICard
          label="Last Two Weeks Check-Ins"
          query={{
            measures: ["PatientCheckIn.total_check_ins"],
            filters: [
              {
                member: "PatientCheckIn.platform_user_id",
                operator: "equals",
                values: [patientId],
              },
            ],
            timeDimensions: [
              {
                dimension: "PatientCheckIn.check_in_date_local",
                dateRange: timeDomain.map((t) => t.toSQLDate()),
              },
            ],
          }}
        />
        <CubeKPICard
          label="Total Check-Ins"
          query={{
            measures: ["PatientCheckIn.total_check_ins"],
            filters: [
              {
                member: "PatientCheckIn.platform_user_id",
                operator: "equals",
                values: [patientId],
              },
            ],
          }}
        />
        <CubeKPICard
          label="Average Weekly Check-Ins"
          query={{
            measures: ["PatientCheckIn.average_bi_weekly_check_ins"],
            filters: [
              {
                member: "PatientCheckIn.platform_user_id",
                operator: "equals",
                values: [patientId],
              },
            ],
          }}
        />
        <ChartCard title="Two Week Mental Fitness Score Trend">
          <ResponsiveContainer height={300}>
            <LineChart
              data={
                // TODO: I don't know why CubeJS recommends this library if we have to parse the data in for the chart to work, either I'm doing something wrong or we should use something else.
                resultSet
                  ? resultSet.tablePivot().map((row) => {
                      const roa = {
                        ...row,
                        ["PatientCheckIn.check_in_score"]: parseInt(
                          row["PatientCheckIn.check_in_score"] || "0"
                        ),
                        ["PatientCheckIn.check_in_date_local"]:
                          DateTime.fromISO(
                            row["PatientCheckIn.check_in_date_local"]
                          ).toMillis(),
                      };
                      return roa;
                    })
                  : []
              }
            >
              <ReferenceArea
                isFront={false}
                ifOverflow="hidden"
                y1={70}
                y2={100}
                fill="#006727"
                fillOpacity={1}
              />
              <ReferenceArea
                isFront={false}
                ifOverflow="hidden"
                y1={55}
                y2={69}
                fill="#0dd606"
                fillOpacity={1}
              />
              <ReferenceArea
                isFront={false}
                ifOverflow="hidden"
                y1={0}
                y2={54}
                fill="#dda35e"
                fillOpacity={1}
              />
              <Tooltip
                labelFormatter={(value) => {
                  return DateTime.fromMillis(value).toFormat("M/d h:mm a");
                }}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="PatientCheckIn.check_in_score"
                name="Mental Fitness Score"
                strokeWidth={3}
              />
              <XAxis
                allowDataOverflow={true}
                // minTickGap={1}
                // tickMargin={-25}
                // scale="time"
                // tickCount={10}
                axisLine={false}
                dataKey="PatientCheckIn.check_in_date_local"
                tickFormatter={(tick) => {
                  return DateTime.fromMillis(tick).toFormat("M/d");
                }}
                ticks={new Array(8).fill(0).map((o, i) =>
                  DateTime.now()
                    .endOf("day")
                    .minus({ days: i * 2 })
                    .toMillis()
                )}
                type="number"
                interval="preserveStart"
                domain={timeDomain.map((t) => t.toMillis())}
              >
                <Label style={{ fontWeight: 700, textAnchor: "middle" }} position="insideBottom" value="Dates" />
              </XAxis>
              <YAxis
                name="Mental Fitness Score"
                dataKey="PatientCheckIn.check_in_score"
                type="number"
                domain={[0, 100]}
                padding={{ left: 0 }}
              >
                <Label style={{ fontWeight: 700, textAnchor: "middle" }} position="insideLeft" angle={270} value="Mental Fitness Score" />
              </YAxis>
            </LineChart>
          </ResponsiveContainer>
          {isLoading ? <FigureLoader /> : null}
          {!isLoading && resultSet && resultSet.tablePivot().length === 0 ? (
            <div className="patient-chart__error">
              <div className="patient-chart__error-message">
                No data in two week range.
              </div>
            </div>
          ) : null}
        </ChartCard>
      </div>
    </div>
  );
}

function App() {
  const expires = parseInt(localStorage.getItem("user_expires"));
  const expired = !expires || new Date(expires) < new Date();
  const [refreshingCredential, setRefreshingCredential] = useState(false);
  const {
    acceptance: {
      organization: {
        account: { group: { id: groupId, name: groupName } = {} } = {},
        name: orgName,
      } = {},
    } = {},
    email: { value: userEmail } = {},
  } = JSON.parse(localStorage.getItem("user_about")) || {};
  const [credential, setCredentials] = useState(
    expired ? null : localStorage.getItem("user_credential")
  );

  // Try to refresh the credential 30 seconds before it expires.
  useEffect(() => {
    if (credential) {
      const timeout = setTimeout(() => {
        const refreshToken = localStorage.getItem("user_refresh");
        if (refreshToken) {
          axios
            .request({
              method: "post",
              url: process.env.REACT_APP_SONDEPLATFORM_REFRESH_URL,
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_SONDEPLATFORM_TOKEN}`,
                "Content-Type": "application/json",
              },
              data: {
                refreshToken,
                idToken: credential,
              },
            })
            .then(setCredentialsFromAuthResponse)
            .catch((e) => {
              clearCredentials();
            });
        } else {
          clearCredentials();
        }
      }, Math.max(expires - Date.now() - 30000, 0));
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [credential]);

  useEffect(() => {
    if (expired) {
      const refreshToken = localStorage.getItem("user_refresh");
      if (refreshToken) {
        setRefreshingCredential(true);
        axios
          .request({
            method: "post",
            url: process.env.REACT_APP_SONDEPLATFORM_REFRESH_URL,
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_SONDEPLATFORM_TOKEN}`,
              "Content-Type": "application/json",
            },
            data: {
              refreshToken,
              idToken: localStorage.getItem("user_credential"),
            },
          })
          .then(setCredentialsFromAuthResponse)
          .catch((e) => {
            clearCredentials();
          })
          .then(() => {
            setRefreshingCredential(false);
          });
      } else {
        clearCredentials();
      }
    }
  }, [expired]);

  const setCredentialsFromAuthResponse = ({
    data: { AccessToken, ExpiresIn, TokenType, RefreshToken, IdToken, me },
  }) => {
    try {
      const { exp } = JSON.parse(atob(IdToken.split(".")[1]));
      localStorage.setItem("user_expires", parseInt(exp) * 1000);
    } catch (e) {
      localStorage.setItem(
        "user_expires",
        new Date() + parseInt(ExpiresIn) * 1000
      );
    }
    if (me) {
      localStorage.setItem("user_about", JSON.stringify(me));
    }
    localStorage.setItem("user_credential", IdToken);
    if (RefreshToken) {
      localStorage.setItem("user_refresh", RefreshToken);
    }
    setCredentials(IdToken);
  };

  const clearCredentials = () => {
    localStorage.removeItem("user_credential");
    localStorage.removeItem("user_expired");
    localStorage.removeItem("user_refresh");
    localStorage.removeItem("user_about");
    setCredentials(null);
  };

  let cubejsApi;
  if (credential) {
    cubejsApi = cubejs(credential, {
      apiUrl: process.env.REACT_APP_CUBEJS_API_URL,
    });
  }

  return (
    <div className="reports">
      {refreshingCredential ? <ScreenLoader /> : null}
      <HashRouter>
        {credential ? (
          <UserProvider>
            <Routes>
              <Route
                path="/"
                index
                element={
                  <div className="reports__organization-title reports__organization-title--large">
                    {orgName}
                  </div>
                }
              ></Route>
              <Route
                path="*"
                index
                element={
                  <div className="reports__organization-title">{orgName}</div>
                }
              ></Route>
            </Routes>
            <div id="logout">
              <span className="username">{userEmail}</span>
              <Link to="/" onClick={clearCredentials}>
                Logout
              </Link>
            </div>
            <UserDetailsProvider>
              <CubeProvider cubejsApi={cubejsApi}>
                <Routes>
                  <Route path="/">
                    <Route
                      index
                      element={<PatientGroupHome groupName={groupName} />}
                    ></Route>
                    <Route path="user">
                      <Route
                        path=":patientId"
                        element={
                          <React.Fragment>
                            <div id="nav__back">
                              <Link to="/">Back to Group</Link>
                            </div>
                            <PatientSummary />
                          </React.Fragment>
                        }
                      />
                    </Route>
                    <Route
                      path="settings"
                      element={
                        <React.Fragment>
                          <div id="nav__back">
                            <Link to="/">Back to Group</Link>
                          </div>
                          <UserDisplaySettings />
                        </React.Fragment>
                      }
                    />
                  </Route>
                </Routes>
              </CubeProvider>
            </UserDetailsProvider>
          </UserProvider>
        ) : (
          <Routes>
            <Route
              path="*"
              element={
                <ClinicianLogin
                  onLogin={(userName, password) => {
                    const request = axios
                      .request({
                        method: "post",
                        url: process.env.REACT_APP_SONDEPLATFORM_LOGIN_URL,
                        headers: {
                          Authorization: `Bearer ${process.env.REACT_APP_SONDEPLATFORM_TOKEN}`,
                          "Content-Type": "application/json",
                        },
                        data: {
                          userName,
                          password,
                        },
                      })
                      .then(setCredentialsFromAuthResponse);
                    return request;
                  }}
                />
              }
            ></Route>
          </Routes>
        )}
      </HashRouter>
      <div className="footer">
        <div className="copyright">
          &copy; {new Date().getFullYear()} | Sonde Health, Inc.{" "}
          {process.env.REACT_APP_VERSION
            ? `(version: ${process.env.REACT_APP_VERSION})`
            : ""}
        </div>
        <div className="tos">
          <a href="https://www.sondehealth.com/privacy">Privacy Policy</a> &amp;{" "}
          <a href="https://www.sondehealth.com/terms-of-service">Terms</a>
        </div>
      </div>
    </div>
  );
}

export default App;
