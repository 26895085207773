import React, { createContext, useState, useReducer } from "react";

export const UserContext = createContext();
export function UserProvider({ children }) {
  const [settings, updateSettings] = useReducer((s, o) => ({ ...s, ...o }), {
    useName: true,
    useEmail: false,
  });
  const [credentials, setCredentials] = useState(null);
  return (
    <UserContext.Provider value={{ settings, credentials, updateSettings }}>
      {children}
    </UserContext.Provider>
  );
}
